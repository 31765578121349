import React, {
  Dispatch,
  FormEventHandler,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Transition } from '@headlessui/react';
import { SetStateAction } from 'jotai';
import Link from 'next/link';
import { CgSearch } from 'react-icons/cg';
import { FaFilter } from 'react-icons/fa';
import { HiArrowNarrowRight, HiChevronUp, HiX } from 'react-icons/hi';
import { useBoolean, useOnClickOutside, useWindowSize } from 'usehooks-ts';

import { GoogleAdsTargeting } from '@/lib/hooks/useGoogleAdsTrageting';
import Divider, { Orientation } from '@/tayara-kit/Divider';

import SearchHistoryStrip from './SearchHistoryStrip';
import ShowToRight from '../../animations/ShowToRight';
import { useElasticSearchSearchInput } from '../../hooks/useElasticSearchServer';
import useFocus from '../../hooks/useFocus';
import KeyCategories from '../KeyCategories';
import RecentClassifiedsList from '../RecentClassifiedsList/RecentClassifiedsList';

const lg = 1024;
export interface LargeSearchInputProps
  extends React.HTMLAttributes<HTMLInputElement> {
  /**
   * Optional click handler
   */
  onClick?: () => void;
  setSearchFocused?: Dispatch<SetStateAction<boolean>>;
  setSearchHasValue?: Dispatch<SetStateAction<boolean>>;
  iconClasses?: string;
}

/**
 * Home page search field
 */
export const LargeSearchInput: React.FC<LargeSearchInputProps> = ({
  className,
  setSearchFocused,
  setSearchHasValue,
  iconClasses,
  ...props
}) => {
  // const [
  //   searchQuery,
  //   setSearchQuery,
  //   initSearchWithQuery,
  //   handleSearchInputOnBlur,
  // ] = useMeilisearchSearchInput();

  const [
    searchQuery,
    setSearchQuery,
    initSearchWithQuery,
    handleSearchInputOnBlur,
  ] = useElasticSearchSearchInput();

  const [targetingValue, setTargetingValue] = useState(searchQuery);

  const [hasValue, setHasValue] = React.useState(false);

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);
    setHasValue(value !== '');
  };

  const {
    setTrue: setAdvancedOn,
    setFalse: setAdvancedOff,
    value: advancedOpen,
  } = useBoolean(false);
  const panelRef = useRef<HTMLDivElement>(null);
  const closeRef = useRef<HTMLButtonElement>(null);
  const [searchInputRef, { hasFocus: inputHasFocus, ref: inputRef }] =
    useFocus<HTMLInputElement>();

  useEffect(() => {
    if (setSearchFocused) setSearchFocused(inputHasFocus);
    if (setSearchHasValue) setSearchHasValue(hasValue);
  }, [inputHasFocus, setSearchFocused, hasValue, setSearchHasValue]);

  // If input has a value but onChange was not emitted
  useEffect(() => {
    if (inputRef && inputRef.value !== '') {
      setHasValue(true);
    }
  }, [inputRef, inputRef?.value, setHasValue]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setTargetingValue(searchQuery);
    if (inputRef) inputRef.blur();
    if (initSearchWithQuery) initSearchWithQuery(e);
  };

  useOnClickOutside(panelRef, setAdvancedOff);
  const [panelRect, setPanelRect] = useState<DOMRect>();
  useEffect(() => {
    setPanelRect(panelRef.current?.getBoundingClientRect());
  }, [advancedOpen]);

  const { width: windowSize } = useWindowSize();

  const panelState = useRef({ translateY: 0, travel: 0, initialY: 0 });
  const handleTouchMove = (event: React.TouchEvent<HTMLButtonElement>) => {
    const touch = event.touches[0];
    if (touch) {
      if (panelState.current.initialY === 0) {
        panelState.current.initialY = touch?.screenY;
      }
      if (panelRef.current) {
        panelRef.current.style.transform = `translateY(${panelState.current.translateY}px)`;
      }

      panelState.current.travel = panelRect
        ? -panelRect.top + touch.screenY - panelState.current.initialY
        : touch.screenY - panelState.current.initialY;

      panelState.current.translateY = panelState.current.travel;
    }
  };

  const handleTouchEnd = () => {
    if (Math.abs(panelState.current.travel) > 200) {
      panelState.current = { translateY: 0, travel: 0, initialY: 0 };
      setAdvancedOff();
      return;
    }
    panelState.current = { translateY: 0, travel: 0, initialY: 0 };
    if (panelRect && panelRef.current) {
      panelRef.current.style.transform = `translateY(${-panelRect.top}px)`;
    }
    setAdvancedOn();
  };
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <div
      className={`w-full relative mx-auto transition-all outline-4 outline-offset-2 outline-neutral-400  ${
        advancedOpen
          ? ' lg:max-w-5xl'
          : 'lg:max-w-[45rem]  max-w-[calc(100%_-_16px)]'
      }`}
      style={
        advancedOpen && windowSize < lg // Media lower than lg size
          ? {
              transform: `translate(-${panelRect?.left}px, -${panelRect?.top}px)`,
              zIndex: 99999 + 1, // 99999 is the navbar and tab bar z-index
              width: '100vw',
            }
          : {}
      }
      ref={panelRef}
    >
      <form onSubmit={handleSubmit}>
        <GoogleAdsTargeting q={targetingValue} />
        <input
          onFocus={setAdvancedOn}
          ref={searchInputRef}
          {...props}
          className={`z-[52] text-2xl cursor-text w-full input-md flex border-[1px] border-gray-300 text-neutral-600 rounded-3xl outline-none shadow-sm  shadow-gray-300  ${
            hasValue ? 'pr-20' : ''
          }
          ${
            advancedOpen
              ? 'lg:rounded-br-none lg:shadow-2xl lg:rounded-bl-none outline-offset-0 rounded-[0] lg:rounded-3xl'
              : ''
          } pb-8 pl-20 outline-1 
              placeholder:text-neutral-400 placeholder:text-lg   lg:placeholder:text-2xl transition-all duration-300 focus:transition-all
                font-extrabold font-arabic h-20 hover:outline-none ${className}`}
          placeholder="Rechercher sur tayara"
          value={searchQuery}
          onChange={(e) => handleSearchQuery(e.target.value)}
          onBlur={handleSearchInputOnBlur}
        />
        {/* Layer on top of the search input */}
        <span
          className={`absolute inset-0 flex items-center justify-between pointer-events-none px-5`}
        >
          {/* West side */}
          <span className="flex">
            <div className="my-auto">
              <CgSearch
                className={`${
                  advancedOpen ? 'text-neutral-700' : 'text-neutral-500'
                } ${iconClasses}`}
                size={40}
              />
            </div>

            <div className=" w-full">
              <div className="pt-10 pl-5 w-full">
                <SearchHistoryStrip
                  onSelect={(value) => handleSearchQuery(value)}
                />
              </div>
            </div>
          </span>

          {/* East side */}
          <div className="relative flex flex-col gap-y-1">
            {/* Go/search button */}
            <div className="h-14 ml-auto">
              <ShowToRight isShowing={hasValue}>
                <button className="flex items-center justify-center px-5 h-14 rounded-2xl pointer-events-auto text-white space-x-1 bg-primary/80  mb-2 -mr-3 hover:bg-primary">
                  <HiArrowNarrowRight size={28} />
                </button>
              </ShowToRight>
            </div>
          </div>

          {/* Advanced search interface layer */}
          <Transition
            show={advancedOpen}
            as={Fragment}
            enter="transition-all duration-150"
            leave="transition-all duration-200"
            enterFrom="scale-y-0 origin-top opacity-0 translate-y-[-50px]"
            enterTo="scale-y-100 opacity-100 origin-top -translate-y-0"
            leaveFrom="scale-y-100 opacity-100 origin-top -translate-y-0"
            leaveTo="scale-y-0 origin-top opacity-0 translate-y-[-50px]"
          >
            <div
              className={`absolute left-0 top-full right-0 w-full bg-white z-50 pointer-events-auto rounded-br-lg rounded-bl-lg pt-3 shadow-2xl`}
            >
              <div
                className={`flex gap-x-2 items-start mt-auto z-[53] transition-all absolute right-0 top-0 pt-3 pr-3`}
              >
                <Link
                  href="/search"
                  passHref
                  className={`text-neutral-500 font-extrabold pointer-events-auto text-xs flex items-center gap-x-1 p-1 rounded-md `}
                >
                  <FaFilter />
                  Filtre Avancé
                </Link>

                <button
                  ref={buttonRef}
                  // type button must be present or the button will actually submit the form
                  type="button"
                  onClick={setAdvancedOff}
                  className={`text-neutral-600 pointer-events-auto p-1 rounded-md bg-neutral-200`}
                >
                  <HiX size={25} />
                </button>
              </div>
              <div className="flex items-stretch flex-col gap-2 min-w-full mt-6 lg:mt-0 pb-8 px-8">
                <RecentClassifiedsList topOffset={70} />
                {/* <Divider
                  className="!mt-3 !mb-0 !bg-neutral-100"
                  orientation={Orientation.HORIZONTAL}
                />
                <FrequentlySearchedKeywordsStrip /> */}
                <Divider
                  className="!mt-3 !mb-0 !bg-neutral-100"
                  orientation={Orientation.HORIZONTAL}
                />

                <div className="mx-0 mt-3">
                  <KeyCategories setAdvancedOff={setAdvancedOff} />
                </div>
              </div>
              <button
                // type button must be present or the button will actually submit the form
                type="button"
                onClick={setAdvancedOff}
                className={`text-neutral-400 text-sm bg-neutral-50 font-bold pointer-events-auto py-2 rounded-br-lg mt-3 rounded-bl-lg flex justify-center items-center gap-x-1 w-full touch-none`}
                ref={closeRef}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <HiChevronUp size={22} />
                Fermer
              </button>
            </div>
          </Transition>
        </span>
      </form>
    </div>
  );
};
