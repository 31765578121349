import React, { FC, useRef } from 'react';

import { HiStar } from 'react-icons/hi';

import { CarouselEvents } from '@/modules/analytics/event-types';
import useRudderTrack from '@/modules/analytics/hooks/useRudderTrack';
import { CarouselType } from '@/modules/analytics/types';
import ClassifiedCard from '@/tayara-kit/ClassifiedCard/ClassifiedCard';
import ClassifiedCardSkel from '@/tayara-kit/Sekeletons/ClassifiedCardSkel';
import XCarousel from '@/tayara-kit/XCarousel';

import GoldenClassified from './GoldenClassified';
import { useElastiSearchAdList } from '../../hooks/useElasticSearchServer';
import { SearchedListingsAction } from '../../store/store';
import { IListingItem } from '../../types';

interface FCCProps {
  ssrSearchedListingsAction?: SearchedListingsAction;
  ssrGoldenListing?: IListingItem | null;
  category?: string | undefined;
}

const FeaturedClassifiedsCarousel: FC<FCCProps> = ({
  ssrGoldenListing,
  ssrSearchedListingsAction,
}) => {
  const portalRef = useRef<HTMLDivElement>(null);

  const { goldenListing, premiumListings } = useElastiSearchAdList(
    ssrSearchedListingsAction,
    ssrGoldenListing
  );
  const premiumListingsLength = premiumListings?.length;
  console.log(premiumListingsLength);
  const { trackCarouselClassifiedClick } = useRudderTrack();
  return (
    <div className="w-full justify-center mb-16  relative flex" ref={portalRef}>
      <div className=" w-full h-full justify-center flex flex-col lg:flex-row items-center gap-6 overflow-x-hidden">
        <div className="min-w-[310px] max-w-[80%] w-full lg:w-[400px] grow">
          <GoldenClassified goldenClassified={goldenListing} />
        </div>

        {/* A la une carousel */}
        <div className="overflow-x-hidden h-full w-full mt-auto mb-0">
          <div className="flex justify-between">
            <h3 className="font-extrabold text-4xl tracking-wide text-neutral-800 flex gap-x-3 items-center">
              <span className="p-1 bg-primary text-white rounded-md">
                <HiStar size={20} />
              </span>
              <span>À la une</span>
            </h3>
            {/* <Link href="" passHref className="flex items-center gap-3 text-sm font-bold pl-3 mt-auto mb-2">
                Afficher tout <HiArrowNarrowRight size={20} />
            </Link> */}
          </div>

          <XCarousel
            portalEl={portalRef.current}
            /* onStopScroll={(progress) => {
            trackCarouselScroll(CarouselEvents.SCROLL_CAROUSEL, {
              scroll_percentage: progress,
              carousel_type: CarouselType.FEATURED_PAID,
              relative_data: null,
            });
          }} */
          >
            <ul className="h-full flex gap-4 pt-8 lg:pt-4">
              {premiumListings ? (
                premiumListings?.map((classified, index) => (
                  <li key={classified.id} className="snap-start scroll-ml-px">
                    <ClassifiedCard
                      onClick={() =>
                        trackCarouselClassifiedClick(
                          CarouselEvents.CLICK_CLASSIFIED,
                          {
                            carousel_type: CarouselType.FEATURED_PAID,
                            classified_details: classified,
                            classified_position: index,
                          }
                        )
                      }
                      classifiedCardData={classified}
                    />
                  </li>
                ))
              ) : (
                <>
                  <li>
                    <ClassifiedCardSkel />
                  </li>
                  <li>
                    <ClassifiedCardSkel />
                  </li>
                  <li>
                    <ClassifiedCardSkel />
                  </li>
                  <li>
                    <ClassifiedCardSkel />
                  </li>
                  <li>
                    <ClassifiedCardSkel />
                  </li>
                  <li>
                    <ClassifiedCardSkel />
                  </li>
                  <li>
                    <ClassifiedCardSkel />
                  </li>
                  <li>
                    <ClassifiedCardSkel />
                  </li>
                </>
              )}
            </ul>
          </XCarousel>
        </div>
      </div>
    </div>
  );
};

export default FeaturedClassifiedsCarousel;
